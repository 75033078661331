import React, { useState, useEffect, useRef } from "react"
import { useClasses } from '../lib/ReactUtils'
import { toUpperCaseFirst, formatNumber  } from "../lib/Utils"
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../styles/Tables'

const PLANS = {
  // https://workspace.google.com/intl/en/pricing.html
  google: {
    'starter': 6,
    'standard': 12,
    'plus': 18
  },
  // https://messageops.com/office-365-pricing/
  ms: {
    'basic': 5,
    'business': 12.50,
    'business+': 20
  },
  oo: {
    'free': 0,
    'business': 9.50,
  }
}

export default function SavingsPage() {
    const root = useClasses()
    const [results, setResults] = useState()
    const [employees, setEmployees] = useState(100)
    const [email, setEmail] = useState(true)
    const [protection, setProtection] = useState(false)
    const [storage, setStorage] = useState(true)
    const [collaboration, setCollaboration] = useState(true)
    const [offline, setOffline] = useState(true)
    const [hybrid, setHybrid] = useState(false)
    const [support, setSupport] = useState(false)
    const [notes, setNotes] = useState([])

    useEffect(() => compute(), [
      employees,
      protection,
      email,
      storage,
      collaboration,
      offline,
      hybrid,
      support
    ])

    const compute = () => {
      var ms = 'basic'
      var google = 'starter'
      var oo = 'free'
      if (collaboration) {
        ms = 'business'
        oo = 'business'
      }
      if (storage) {
        google = 'standard'
        oo = 'business'
      }
      if (offline || hybrid) {
        oo = 'business'
        ms = 'business'
        google = null
      }
      if (protection) {
        google = false
        ms = 'business+'
        oo = 'business'
      }
      if (support) {
        ms = 'business+'
        if (oo == 'free') oo = 'business'
        if (google) google = 'plus'
      }
      const _results = { }
      if (!google) {
        _results.headers = ['OneOffice', 'MS365']
      }
      else {
        _results.headers = ['OneOffice', 'MS365', 'Google']
      }

      _results.rows = []
      var row

      // plan
      row = [{label:'Plan'}, toUpperCaseFirst(oo), toUpperCaseFirst(ms)]
      if (google) row.push(toUpperCaseFirst(google))
      _results.rows.push(row)

      var pricing = PLANS.oo[oo]
      if (employees >= 2000) pricing = 6.5
      else if (employees >= 1700) pricing = 7
      else if (employees >= 1500) pricing = 7.5
      else if (employees >= 1250) pricing = 8
      else if (employees >= 1000) pricing = 8.5
      else if (employees >= 700) pricing = 8.75
      else if (employees >= 500) pricing = 9
      else if (employees >= 200) pricing = 9.25

      // monthly per user
      row = ['Monthly / User', '$'+pricing, '$'+PLANS.ms[ms]]
      if (google) row.push('$' + PLANS.google[google])
      _results.rows.push(row)

      var totalOO = 5 * 12 * pricing * employees
      var totalMS = 5 * 12 * PLANS.ms[ms] * employees

      // users
      const formattedEmployees = formatNumber(employees)
      row = ['Users', formattedEmployees, formattedEmployees]
      if (google) row.push(formattedEmployees)
      _results.rows.push(row)

      // monthly total
      row = [{label:'Monthly', className:'font-bold text-primary-dark'}, {label:'$'+formatNumber(pricing*employees), className:'font-bold text-primary-dark'}, {label:'$'+formatNumber(PLANS.ms[ms]*employees), className:'font-bold text-primary-dark'}]
      if (google) row.push({label:'$' + formatNumber(PLANS.google[google] * employees), className:'font-bold text-primary-dark'})
      _results.rows.push(row)

      // space per user
      row = ['Storage']
      if (oo == 'free') row.push('2GB +$2/10G')
      else row.push('1TB+')
      row.push('1TB+')
      if (google == 'starter') row.push({label:'30G', className:'text-red-500 font-semibold'})
      else if (google) row.push('1TB+')
      _results.rows.push(row)

      // data loss
      row = [{label:'Data loss prevention', className:'font-bold'}]
      if (oo == 'free') row.push({label:'None', className:'text-red-500 font-semibold'})
      else row.push({label:'Included', className:'text-green-600 font-semibold'})
      if (ms == 'business+') row.push({label:'Included', className:'text-green-600 font-semibold'})
      else row.push({label:'None', className:'text-red-500 font-semibold'})
      if (google) row.push({label:'None', className:'text-red-500 font-semibold'})
      _results.rows.push(row)

      // security
      row = [{label:'Security', className:'font-bold'}]
      row.push({label:'Very Strong', className:'text-green-600 font-semibold'})
      if (ms == 'business+') row.push({label:'Very Strong', className:'text-green-600 font-semibold'})
      else if (ms == 'business') row.push({label:'Strong', className:'text-green-600 font-semibold'})
      else row.push({label:'Basic', className:'text-red-500 font-semibold'})
      if (google) row.push({label:'Basic', className:'text-red-500 font-semibold'})
      _results.rows.push(row)

      var additional
      if (hybrid) {
        const roundedEmployees = Math.round(employees/100)*100

        row = ['Server Costs']
        additional = Math.max(700, 9*roundedEmployees)
        totalOO += additional
        row.push('$'+formatNumber(additional))
        additional = Math.max(2000, 25*roundedEmployees)
        totalMS += additional
        row.push('$'+formatNumber(additional))
        _results.rows.push(row)

        row = ['IT Costs / Year']
        additional = Math.max(1000, 8*roundedEmployees)
        totalOO += additional*5
        row.push('$'+formatNumber(additional))
        additional = Math.max(5000, 32*roundedEmployees)
        totalMS += additional*5
        row.push('$'+formatNumber(additional))
        _results.rows.push(row)
      }

      if (totalOO < totalMS) {
        _results.savings = formatNumber(totalMS - totalOO)
      }

      const _notes = []
      if (oo == 'free' && !email) {
        _notes.push('You don\'t really need much, why are you shopping for a platform?')
      }
      else if (google == 'starter' || ms == 'basic') {
        _notes.push('Your team does not have offline local productivity suite, they can only edit documents online.')
        _notes.push('You have no protection against breaches, ransomware and data loss!')
      }
      else if (!hybrid) {
        _notes.push('You do not have local server in your office in case the internet goes down. Select "Hybrid" above.')
      }

      setNotes(_notes)
      setResults(_results)
    }

    return (
      <Layout short>
        <SEO title='Savings'/>
        <main className="main" ref={root}>
          <div className="heading-separator"/>
          <h1 className="title">Compute your Savings</h1>
          <h2 className="subtitle">Make sure you get the best platform for your $</h2>
          <div className="description">
            <p>This interactive calculator will chose the appropriate plans and estimate your actual costs for the main three productivity platforms.</p>
            <p>All you have to do is answer the quick questionnaire below to get a fairly accurate estimate of your one-time as well as recurring costs.</p>
          </div>

          <ol className="border-l-2 ml-2 border-primary-dark mt-12 mb-12">
            <li>
              <div className="flex flex-start items-start">
                <div className="bg-primary-dark w-4 h-4 flex items-center justify-center rounded-full -ml-2 mr-3"></div>
                <div className="flex flex-col items-start gap-y-3 -mt-1">
                  <h4 className="text-gray-600 font-normal w-auto text-sm">Step 1 of 2</h4>
                  <div className="flex flex-row items-center -mt-3 w-full">
                    <h3 className="text-gray-800 font-semibold text-xl md:text-2xl w-auto">
                      Number of Employees
                    </h3>
                    <input className='ml-5 rounded-none text-lg md:text-xl font-semibold text-center w-18 basic border-b-2 border-blue-300 hocus:border-primary-dark py-0 px-2 bg-transparent' type="text" value={employees} onChange={(e) => setEmployees(e.currentTarget.value)}/>
                  </div>
                </div>
              </div>
              <div className="ml-5 mb-14 mt-2">
                <p className="text-gray-600">
                  We need this to estimate your license fees as well as support and IT costs for the services you will chose.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-start items-start">
                <div className="bg-primary-dark w-4 h-4 rounded-full -ml-2 mr-3"></div>
                <div className="flex-1 flex flex-col items-start gap-y-3 -mt-1">
                  <h4 className="text-gray-600 font-normal w-auto text-sm">Step 2 of 2</h4>
                  <div className="flex flex-row items-center -mt-3 w-full">
                    <h3 className="text-gray-800 font-semibold text-xl md:text-2xl w-auto">
                      Choose features / services that are important to you
                    </h3>
                  </div>
                </div>
              </div>
              <div className="ml-5 mb-16 mt-2">
                <p className="text-gray-600 mb-5">
                  Please make sure you don't miss a key feature or your deployment will be incomplete and your cost estimates inaccurate.
                </p>
                <form>
                  <div onClick={(e) => setEmail(!email)}>
                    <input type='checkbox' name='email' checked={email}/>
                    <label htmlFor='email'>
                      Email, Calendar & Contacts
                      <small className='hidden md:inline ml-4 text-gray-600'>[Business-grade emails for your employees]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setStorage(!storage)}>
                    <input type='checkbox' name='storage' checked={storage}/>
                    <label htmlFor='storage'>
                      Online Storage
                      <small className='hidden md:inline ml-4 text-gray-600'>[Do you need more than 30G of online storage]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setCollaboration(!collaboration)}>
                    <input type='checkbox' name='collaboration' checked={collaboration}/>
                    <label htmlFor='collaboration'>
                      Collaboration
                      <small className='hidden md:inline ml-4 text-gray-600'>[Team collaboration - like SharePoint]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setOffline(!offline)}>
                    <input type='checkbox' name='offline' checked={offline}/>
                    <label htmlFor='offline'>
                      <span className='font-semibold'>Desktop Productivity Suite</span>
                      <small className='hidden md:inline ml-4 text-gray-600'>[The ability to edit documents on your computer]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setProtection(!protection)}>
                    <input type='checkbox' name='protection' checked={protection}/>
                    <label htmlFor='hybrid'>
                      <span className='font-semibold text-red-500'>Ransomware / Data Protection</span>
                      <small className='hidden md:inline ml-4 text-gray-600'>[Active threat protection and data backup]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setHybrid(!hybrid)}>
                    <input type='checkbox' name='hybrid' checked={hybrid}/>
                    <label htmlFor='hybrid'>
                      <span className='font-semibold'>Hybrid / On-Premises</span>
                      <small className='hidden md:inline ml-4 text-gray-600'>[Do you need servers / file backups at your offices]</small>
                    </label>
                  </div>
                  <div onClick={(e) => setSupport(!support)}>
                    <input type='checkbox' name='support' checked={support}/>
                    <label htmlFor='support'>
                      Support / SLA
                      <small className='hidden md:inline ml-4 text-gray-600'>[Do you need a high-level of service & support]</small>
                    </label>
                  </div>
                </form>
              </div>
            </li>
            <li>
              <div className="flex flex-start items-end">
                <div className="bg-primary-dark w-4 h-4 rounded-full -ml-2 mr-3 -mt-2"></div>
                <h4 className="text-gray-600 font-normal w-auto text-sm">Let's rumble!</h4>
              </div>
            </li>
          </ol>


          { results ? 
            <>
              { results.savings ?
                <div className='mx-auto font-bold text-gray-800 w-full lg:w-3/4 rounded py-6 px-6 shadow mb-8 bg-gradient-to-br from-red-100 to-rose-200 flex justify-between items-center px-3 md:px-6'>
                  <span className='text-lg md:text-xl'>Savings over 5 years</span>
                  <span className='text-lg md:text-xl'>${results.savings}</span>
                </div> : null
              }
              <div className='mx-auto w-full lg:w-3/4 rounded py-4 md:py-6 px-3 md:px-6 shadow mb-8 bg-gradient-to-br from-yellow-100 to-orange-200'>
                <table className='w-full'>
                  <thead>
                    <tr className='bg-orange-200 rounded'>
                      <th></th>
                      {results.headers.map((h, index) => 
                        <th key={index} className='text-gray-900 text-lg py-2 mx-auto text-center text-sm md:text-base lg:text-lg'>
                          <span>{h}</span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='h-4'/>
                    {results.rows.map((row, index) =>
                      <tr className='row-border border-orange-300' key={index}>
                        { typeof row[0] == 'string' ?
                          <td className='cell pl-1 text-sm md:text-base'>{row[0]}</td>:
                          <td className={'cell pl-1 text-sm md:text-base ' + row[0].className}>{row[0].label}</td>
                        }
                        { typeof row[1] == 'string' ?
                          <td className='cell font-light text-center text-sm md:text-base'>{row[1]}</td>:
                          <td className={'cell font-light text-center text-sm md:text-base ' + row[1].className}>{row[1].label}</td>
                        }
                        { typeof row[2] == 'string' ?
                          <td className='cell font-light text-center text-sm md:text-base'>{row[2]}</td> :
                          <td className={'cell font-light text-center text-sm md:text-base ' + row[2].className}>{row[2].label}</td>
                        }
                        { row[3] && 
                          <>
                            { typeof row[3] == 'string' ?
                              <td className='cell font-light text-center text-sm md:text-base'>{row[3]}</td> :
                              <td className={'cell font-light text-center text-sm md:text-base ' + row[3].className}>{row[3].label}</td>
                            }
                          </>  
                        }
                      </tr>
                    )}
                  </tbody>
                </table>
                { notes.length ?
                  <>
                    <h3 className='font-bold text-xl mt-10 mb-2 text-gray-800 mt-10'>Notes</h3>
                    { notes.map((r, i) => <p key={i} className='leading-snug font-semibold text-gray-700 mb-1 text-base'>{r}</p>)}
                  </> : null
                }
              </div>
            </> : null
          }

        </main>
      </Layout>
    )
}
